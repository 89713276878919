"use strict";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import styled from "lib/styled-components";
import { useCallback, useRef } from "react";
import { animated, easings, useSpring, useTransition } from "react-spring";
import { useGesture } from "react-use-gesture";
import { Z_INDEX } from "theme/zIndex";
import { isMobileWeb } from "utilities/src/platform";
export const MODAL_TRANSITION_DURATION = 200;
const AnimatedDialogOverlay = animated(DialogOverlay);
const StyledDialogOverlay = styled(AnimatedDialogOverlay)`
  will-change: transform, opacity;
  &[data-reach-dialog-overlay] {
    z-index: ${Z_INDEX.modalBackdrop};
    background-color: transparent;
    overflow: hidden;

    display: flex;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
      align-items: flex-end;
    }
    overflow-y: ${({ $scrollOverlay }) => $scrollOverlay && "scroll"};
    justify-content: center;

    background-color: ${({ theme }) => theme.scrim};
  }
`;
function dimensionsToCss(dimensions) {
  if (typeof dimensions === "number") {
    return `${dimensions}px`;
  }
  return dimensions;
}
const AnimatedDialogContent = animated(DialogContent);
const StyledDialogContent = styled(AnimatedDialogContent)`
  overflow-y: auto;

  &[data-reach-dialog-content] {
    margin: auto;
    background-color: ${({ theme }) => theme.surface2};
    border: ${({ theme, $hideBorder }) => !$hideBorder && `1px solid ${theme.surface3}`};
    box-shadow: ${({ theme }) => theme.deprecated_deepShadow};
    padding: 0px;
    width: 50vw;
    overflow-y: auto;
    overflow-x: hidden;
    ${({ $height }) => $height && `height: ${dimensionsToCss($height)};`}
    ${({ $maxHeight }) => $maxHeight && `max-height: ${dimensionsToCss($maxHeight)};`}
    ${({ $minHeight }) => $minHeight && `min-height: ${dimensionsToCss($minHeight)};`}
    ${({ $maxWidth }) => $maxWidth && `max-width: ${dimensionsToCss($maxWidth)};`}
    display: ${({ $scrollOverlay }) => $scrollOverlay ? "inline-table" : "flex"};
    border-radius: 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      width: 65vw;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
      margin: 0;
      width: 100vw;
      border-radius: 20px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;
export default function Modal({
  isOpen,
  onDismiss,
  minHeight,
  maxHeight = "90vh",
  maxWidth = 420,
  height,
  initialFocusRef,
  children,
  onSwipe = onDismiss,
  $scrollOverlay,
  hideBorder = false,
  slideIn
}) {
  const ref = useRef(null);
  useOnClickOutside(ref, () => isOpen && onDismiss ? onDismiss() : void 0);
  const handleEscape = useCallback(
    (e) => {
      if (e.key === "Escape" && isOpen && onDismiss) {
        onDismiss();
      }
    },
    [isOpen, onDismiss]
  );
  const fadeTransition = useTransition(isOpen, {
    config: { duration: MODAL_TRANSITION_DURATION },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });
  const slideTransition = useTransition(isOpen, {
    config: { duration: MODAL_TRANSITION_DURATION, easing: easings.easeInOutCubic },
    from: { transform: "translateY(40px)" },
    enter: { transform: "translateY(0px)" },
    leave: { transform: "translateY(40px)" }
  });
  const [{ y }, set] = useSpring(() => ({ y: 0, config: { mass: 1, tension: 210, friction: 20 } }));
  const bind = useGesture({
    onDrag: (state) => {
      set({
        y: state.down ? state.movement[1] : 0
      });
      if (state.movement[1] > 300 || state.velocity > 3 && state.direction[1] > 0) {
        onSwipe?.();
      }
    }
  });
  return <div tabIndex={0} onKeyUp={handleEscape}>{fadeTransition(
    ({ opacity }, item) => item && <StyledDialogOverlay
      style={{
        opacity: opacity.to({ range: [0, 1], output: [0, 1] }),
        background: "rgba(20, 9, 9, 0.33)",
        bottom: 0,
        left: 0,
        overflow: "auto",
        position: "fixed",
        right: 0,
        top: 0
      }}
      onDismiss={onDismiss}
      initialFocusRef={initialFocusRef}
      unstable_lockFocusAcrossFrames={false}
      $scrollOverlay={$scrollOverlay}
    >{slideTransition(
      (styles, item2) => item2 && <StyledDialogContent
        ref={ref}
        {...isMobileWeb ? {
          ...bind(),
          style: { transform: y.interpolate((y2) => `translateY(${y2 > 0 ? y2 : 0}px)`) }
        } : slideIn ? { style: styles } : {}}
        aria-label="dialog"
        $height={height}
        $minHeight={minHeight}
        $maxHeight={maxHeight}
        $scrollOverlay={$scrollOverlay}
        $hideBorder={hideBorder}
        $maxWidth={maxWidth}
      >
        {
          /* prevents the automatic focusing of inputs on mobile by the reach dialog */
        }
        {!initialFocusRef && isMobileWeb ? <div tabIndex={1} /> : null}
        {children}
      </StyledDialogContent>
    )}</StyledDialogOverlay>
  )}</div>;
}
