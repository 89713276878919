import { Token, UNI_ADDRESSES } from '@uniswap/sdk-core'
import { UniverseChainId } from 'uniswap/src/types/chains'

export const USDC_GOERLI = new Token(
  UniverseChainId.Goerli,
  '0x07865c6e87b9f70255377e024ace6630c1eaa37f',
  6,
  'USDC',
  'USD//C',
)
export const USDC_SEPOLIA = new Token(
  UniverseChainId.Sepolia,
  '0x6f14C02Fc1F78322cFd7d707aB90f18baD3B54f5',
  6,
  'USDC',
  'USD//C',
)

export const DAI = new Token(
  UniverseChainId.Mainnet,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin',
)
export const USDT = new Token(
  UniverseChainId.Mainnet,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
)

export const DAI_OPTIMISM = new Token(
  UniverseChainId.Optimism,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin',
)
export const USDC_OPTIMISM = new Token(
  UniverseChainId.Optimism,
  '0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85',
  6,
  'USDC',
  'USD//C',
)
export const USDC_OPTIMISM_GOERLI = new Token(
  UniverseChainId.OptimismGoerli,
  '0xe05606174bac4A6364B31bd0eCA4bf4dD368f8C6',
  6,
  'USDC',
  'USD//C',
)

export const USDC_BASE = new Token(
  UniverseChainId.Base,
  '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_BNB = new Token(UniverseChainId.Bnb, '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', 18, 'USDC', 'USDC')

export const MATIC_POLYGON = new Token(
  UniverseChainId.Polygon,
  '0x0000000000000000000000000000000000001010',
  18,
  'MATIC',
  'Matic',
)
export const DAI_POLYGON = new Token(
  UniverseChainId.Polygon,
  '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
  18,
  'DAI',
  'Dai Stablecoin',
)
export const USDC_POLYGON = new Token(
  UniverseChainId.Polygon,
  '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
  6,
  'USDC',
  'USD Coin',
)
export const USDC_POLYGON_MUMBAI = new Token(
  UniverseChainId.PolygonMumbai,
  '0x0fa8781a83e46826621b3bc094ea2a0212e71b23',
  6,
  'USDC',
  'USD Coin',
)

export const USDB_BLAST = new Token(
  UniverseChainId.Blast,
  '0x4300000000000000000000000000000000000003',
  18,
  'USDB',
  'USDB',
)

export const USDC_ARBITRUM = new Token(
  UniverseChainId.ArbitrumOne,
  '0xaf88d065e77c8cC2239327C5EDb3A432268e5831',
  6,
  'USDC',
  'USD//C',
)
export const DAI_ARBITRUM_ONE = new Token(
  UniverseChainId.ArbitrumOne,
  '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
  18,
  'DAI',
  'Dai stable coin',
)
export const USDC_ARBITRUM_GOERLI = new Token(
  UniverseChainId.ArbitrumGoerli,
  '0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892',
  6,
  'USDC',
  'USD//C',
)

export const USDC_AVALANCHE = new Token(
  UniverseChainId.Avalanche,
  '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
  6,
  'USDC',
  'USDC Token',
)

export const USDC_CELO = new Token(
  UniverseChainId.Celo,
  '0xceba9300f2b948710d2653dd7b07f33a8b32118c',
  6,
  'USDC',
  'USD Coin',
)
export const CUSD_CELO = new Token(
  UniverseChainId.Celo,
  '0x765DE816845861e75A25fCA122bb6898B8B1282a',
  18,
  'cUSD',
  'Celo Dollar',
)
export const CUSD_CELO_ALFAJORES = new Token(
  UniverseChainId.CeloAlfajores,
  '0x874069Fa1Eb16D44d622F2e0Ca25eeA172369bC1',
  18,
  'CUSD',
  'Celo Dollar',
)

export const USDC_ZORA = new Token(
  UniverseChainId.Zora,
  '0xCccCCccc7021b32EBb4e8C08314bD62F7c653EC4',
  6,
  'USDC',
  'USD Coin',
)

export const USDC = new Token(
  UniverseChainId.Mainnet,
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  6,
  'USDC',
  'USD//C',
)

export const USDT_BNB = new Token(
  UniverseChainId.Bnb,
  '0x55d398326f99059ff775485246999027b3197955',
  18,
  'USDT',
  'TetherUSD',
)

export const USDC_ZKSYNC = new Token(
  UniverseChainId.Zksync,
  '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
  6,
  'USDC',
  'USDC',
)

export const USDC_ABSTRACT_TESTNET = new Token(
  UniverseChainId.AbstractTestnet,
  '0xe4C7fBB0a626ed208021ccabA6Be1566905E2dFc',
  6,
  'USDC',
  'USDC',
)

export const USDC_ZERO = new Token(
  UniverseChainId.Zero,
  '0x6a6394F47DD0BAF794808F2749C09bd4Ee874E70',
  6,
  'USDC',
  'USDC',
)

export const USDC_BOB = new Token(UniverseChainId.BOB, '0xe75D0fB2C24A55cA1e3F96781a2bCC7bdba058F0', 6, 'USDC', 'USDC')
export const USDC_CYBER = new Token(
  UniverseChainId.CYBER,
  '0x81759adbf5520ad94da10991dfa29ff147d3337b',
  6,
  'USDC',
  'USDC',
)

export const USDC_SHAPE = new Token(
  UniverseChainId.SHAPE,
  '0xdb7DD8B00EdC5778Fe00B2408bf35C7c054f8BBe',
  6,
  'USDC',
  'USDC',
)

export const USDC_INK = new Token(
  UniverseChainId.INK,
  '0xF1815bd50389c46847f0Bda824eC8da914045D14',
  6,
  'USDC.e',
  'Bridged USDC (Stargate)',
)

export const USDC_REDSTONE = new Token(
  UniverseChainId.REDSTONE,
  '0xD5d59fC063e7548b6015A36fEb10B875924A19be',
  6,
  'USDC.e',
  'Bridged USDC (Lattice)',
)

export const USDC_REDSTONE_GARNET = new Token(
  UniverseChainId.REDSTONE_GARNET,
  '0xD2cA61F265a2dA2d2CD7607F05C26eBfA18AD5F6',
  6,
  'mUSDC',
  'Mock USDC',
)

export const USDC_ABSTRACT_MAINNET = new Token(
  UniverseChainId.AbstractMainnet,
  '0x84A71ccD554Cc1b02749b35d22F684CC8ec987e1',
  6,
  'USDC.e',
  'Bridged USDC',
)

export const USDC_ANIME_TESTNET = new Token(
  UniverseChainId.AnimeTestnet,
  '0x69D30A49fcbaB7142d604635772B7Eef958aE0bd',
  6,
  'USDC',
  'USDC',
)

export const USDC_MODE = new Token(
  UniverseChainId.Mode,
  '0xd988097fb8612cc24eeC14542bC03424c656005f',
  6,
  'USDC',
  'USDC',
)

export const WBTC = new Token(
  UniverseChainId.Mainnet,
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  8,
  'WBTC',
  'Wrapped BTC',
)

export const UNI = {
  [UniverseChainId.Mainnet]: new Token(
    UniverseChainId.Mainnet,
    UNI_ADDRESSES[UniverseChainId.Mainnet] as string,
    18,
    'UNI',
    'Uniswap',
  ),
  [UniverseChainId.Goerli]: new Token(
    UniverseChainId.Goerli,
    UNI_ADDRESSES[UniverseChainId.Goerli] as string,
    18,
    'UNI',
    'Uniswap',
  ),
}
