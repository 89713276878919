"use strict";
const ABSTRACT_LIST = getTokenListApiURL("abstract-testnet");
const REDSTONE_GARNET_LIST = getTokenListApiURL("garnet");
const ZERO_LIST = getTokenListApiURL("zero");
const CYBER_LIST = getTokenListApiURL("cyber");
const BOB_LIST = getLegacyTokenListApiURL("60808");
const SHAPE_LIST = getTokenListApiURL("shape");
const INK_LIST = getTokenListApiURL("ink");
const REDSTONE_LIST = getTokenListApiURL("redstone");
const ABSTRACT_MAINNET_LIST = getTokenListApiURL("abstract");
const ANIME_TESTNET = getTokenListApiURL("anime-testnet");
const MODE_LIST = getTokenListApiURL("mode");
export const DEFAULT_INACTIVE_LIST_URLS = [];
export const DEFAULT_ACTIVE_LIST_URLS = [
  ABSTRACT_LIST,
  ZERO_LIST,
  CYBER_LIST,
  BOB_LIST,
  SHAPE_LIST,
  INK_LIST,
  REDSTONE_LIST,
  REDSTONE_GARNET_LIST,
  ABSTRACT_MAINNET_LIST,
  ANIME_TESTNET,
  MODE_LIST
];
export const DEFAULT_LIST_OF_LISTS = [...DEFAULT_ACTIVE_LIST_URLS];
function getTokenListApiURL(slug) {
  return `https://api-${slug}.reservoir.tools/tokenlist/v1`;
}
function getLegacyTokenListApiURL(chainId) {
  return `https://api.relay.link/tokenlist?chainId=${chainId}`;
}
